import React from "react";
import * as styles from "./workDetails.module.scss"
import {Col, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import ListBullet from "../../svg/listBullet.svg";

export default function WorkDetailsTile(props: WorkDetailsTileProps) {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "work-details/image-1.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
    image2: file(relativePath: {eq: "work-details/image-2.png"}) {
        childImageSharp {
            gatsbyImageData(quality: 70, layout: CONSTRAINED)
        }
    }
}`);

    return <div className={styles.tileWrapper}>
        <Row className={styles.rowWrapper}>
            <Col className={styles.firstImageCol} md={{span: 6}} xl={{offset: 1, span: 5}}>
                <GatsbyImage className={styles.imageFirst} alt={"person"}
                             image={data.image1.childImageSharp.gatsbyImageData}/>
            </Col>
            <Col md={{span: 6}} xl={{span: 5}} className={styles.colWrapper}>
                <div className={styles.header}>
                    {props.firstHeader}
                </div>
                <div className={styles.text}>
                    {props.firstText}
                </div>
                <div className={styles.header}>
                    {props.secondHeader}
                </div>
                <div className={styles.text}>
                    {props.secondText}
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={{span: 6}} xl={{offset: 1, span: 5}} className={styles.colWrapper}>
                <div className={styles.header}>
                    {props.thirdHeader}
                </div>
                <div className={styles.secondColTextWrapper}>
                    <div className={styles.text}>
                        {props.thirdText}
                    </div>
                    <div className={styles.listItems}>
                        {props.list.map((item: any, idx: number) => {
                            return <ListItem key={idx} text={item.text}/>
                        })}
                    </div>
                </div>
            </Col>
            <Col md={{span: 6}} className={styles.imageCol} xl={{span: 5}}>
                <GatsbyImage alt={"person"} image={data.image2.childImageSharp.gatsbyImageData}/>
            </Col>
        </Row>
    </div>
}


function ListItem(props: { text: string }) {
    return <div className={styles.listItemWrapper}>
        <div className={styles.bulletWrapper}>
            <ListBullet/>
        </div>
        <div className={styles.listItem}>
            {props.text}
        </div>
    </div>
}

interface WorkDetailsTileProps {
    firstHeader: string,
    firstText: string,
    secondHeader: string,
    secondText: string,
    thirdHeader: string,
    thirdText: string,
    list: any;
}
