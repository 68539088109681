import React, {ReactElement} from "react"
import * as styles from "./workDetailsHero.module.scss"
import {Col, Row} from "react-bootstrap";

export default function WorkDetailsHero(props: WorkDetailsHeroProps) {

    const style = {
        background: props.background,
    }

    return <div className={styles.container}>
        <Row className={styles.cardWrapper}
                    style={style}>
            <Col className={styles.infoWrapper}
                xs={{span: 12}} lg={{span: 6}}>
                <div className={styles.name}>
                    {props.name}
                </div>
                <div className={styles.header}>
                    {props.header}
                </div>
                <div className={styles.text}>
                    {props.text}
                </div>
            </Col>
            <Col className={styles.logoWrapper} xs={{span: 12}}
                lg={{span: 6}}>
                {props.logo}
            </Col>
        </Row>
    </div>
}

interface WorkDetailsHeroProps {
    logo?: ReactElement,
    name?: string,
    header?: string,
    text?: string,
    background?: string;
}
