import React, {ReactElement} from "react";
import {Col, Row} from "react-bootstrap";
import {Statistic} from "../clientCard/clientCard";
import * as styles from "./workDetailsStats.module.scss";

export default function WorkDetailsStats(props: WorkDetailsStatsProps) {

    const backgroundStyle = {
        background: props.background,
    };

    const smallValueStyle = {
        fontSize: 36
    };

    return <Row className={styles.wrapper}>
        {props.statistics.map((s, i) => {
            const WorkDetailsImage = require(`../../svg/pointers/${s.iconName}`).default as ReactElement;
            return <Col key={i} xs={12} md={6} lg={3}>
                <div className={styles.card} style={backgroundStyle}>
                    <WorkDetailsImage className={styles.icon}/>
                    <div className={styles.value}
                         style={s.value.length > 6 ? smallValueStyle : undefined}>{s.value}</div>
                    <div className={styles.name}>{s.name}</div>
                </div>
            </Col>
        })}
    </Row>
}

interface WorkDetailsStatsProps {
    statistics: Statistic[],
    background: string
}
