import React from "react";
import {Helmet} from "react-helmet";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Layout from "../components/layout/layout";
import WorkDetailsHero from "../components/workDetailsHero/workDetailsHero";
import WorkDetailsStats from "../components/workDetailsStats/workDetailsStats";
import GithubLogoSVG from "../svg/githubLogo.svg";
import ForgerockSVG from "../svg/clients/forgerockSVG.svg";
import WorkDetailsTile from "../components/workDetails/workDetailsTile";
import OurWork from "../components/ourWork/ourWork";
import Cta from "../components/cta/cta";

export default function WorkDetails({pageContext}: any) {

    // there you have client information (from ourWorkData.json) - just make sure that each client name is unique!
    const {client} = pageContext;

    return <>
        <Helmet>
            <meta charSet="utf-8"/>
            <html lang="en"/>
            <title>Recoded Recruitment</title>
            <meta name="description"
                  content="Hiring top talent in tech just got easy. Partner with us for expert tech industry recruitment. Choose from our Search, Contingent, or Embedded services for tailored solutions to match your needs."/>
            <meta name="theme-color" content="#F6F4FB"></meta>
        </Helmet>
        <Layout header={<Header transparent={false}/>}
                footer={<Footer/>}>
            <WorkDetailsHero logo={client.slug.includes('github') ? <GithubLogoSVG/> : <ForgerockSVG />}
                             name={`${client.name} Case Study`}
                             header={client.header}
                             text={client.textLong}
                             background={client.color}/>
            <WorkDetailsStats statistics={client.statistics} background={client.color}/>
            <WorkDetailsTile firstHeader={client.workDetailsTile.firstHeader}
                             firstText={client.workDetailsTile.firstText}
                             secondHeader={client.workDetailsTile.secondHeader}
                             secondText={client.workDetailsTile.secondText}
                             thirdHeader={client.workDetailsTile.thirdHeader}
                             thirdText={client.workDetailsTile.thirdText} list={client.workDetailsTile.list}/>
            <Cta title="Benefit from the embedded model" buttonText="Learn more" buttonTarget="/services/embedded" />
            {/* <OurWork/>
            <div style={{marginBottom: "3rem"}}/> */ }
        </Layout>
    </>
}
