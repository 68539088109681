import * as styles from "./cta.module.scss";
import JSONData from "../../../content/cta.json";
import * as React from "react";
import {LinkButton} from "../linkButton/linkButton";

export default function Cta(props: CtaComponentProps) {
    return <div style={{maxWidth:props.maxWidth}} className={styles.cta}>
        <span className={styles.title}>{props.title ? props.title : JSONData.title}</span>
        <LinkButton to={props.buttonTarget ? props.buttonTarget : JSONData.target}>
            {props.buttonText ? props.buttonText : JSONData.buttonText}
        </LinkButton>
    </div>
}

interface CtaComponentProps{
    buttonText?: string,
    title?: string,
    buttonTarget?: string,
    maxWidth?: string;
}
