// extracted by mini-css-extract-plugin
export var bulletWrapper = "workDetails-module--bulletWrapper--9b48f";
export var colWrapper = "workDetails-module--colWrapper--64ba4";
export var firstImageCol = "workDetails-module--firstImageCol--d509e";
export var header = "workDetails-module--header--87339";
export var imageCol = "workDetails-module--imageCol--55d21";
export var imageFirst = "workDetails-module--imageFirst--3b3f5";
export var listItem = "workDetails-module--listItem--4760f";
export var listItemWrapper = "workDetails-module--listItemWrapper--7fc20";
export var rowWrapper = "workDetails-module--rowWrapper--aa6a4";
export var secondColTextWrapper = "workDetails-module--secondColTextWrapper--76d87";
export var text = "workDetails-module--text--a1984";
export var tileWrapper = "workDetails-module--tileWrapper--af39a";